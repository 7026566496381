import { Injectable } from '@angular/core';

import {
  catchError,
  debounceTime,
  filter,
  map,
  type Observable,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator, Store } from '@ngrx/store';

import { distinctUntilObjectChanged } from '@ocp/utils/rxjs';

import { API_REQUEST_DEBOUNCE_TIME } from '@libs/core/constants';
import { createListPayload } from '@libs/core/utils';
import { globalActions, globalFeature } from '@libs/core/store';

import { taskFeature } from './task.reducer';
import { taskActions } from './task.actions';
import type {
  TTaskListRequest,
  TTaskListResponse,
  TTaskQueryField,
  TTaskSortField,
} from '../types';
import { TaskApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class TaskEffects {
  constructor(
    private _taskApiService: TaskApiService,
    private _actions$: Actions,
    private _store: Store,
  ) {}

  loadTaskList$ = createEffect(() =>
    this._fetchList$(
      [taskActions.setQueryData, taskActions.resetQueryFilter, taskActions.setQueryFilterDisabled],
      taskActions.loadListSuccess,
      taskActions.loadListFailure,
    ),
  );

  loadList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(taskActions.loadList),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(taskFeature.selectPagination),
        this._store.select(taskFeature.selectSort),
        this._store.select(taskFeature.selectQuery),
        this._store.select(taskFeature.selectRecords),
      ),
      switchMap(([, projectId, pagination, sort, query, records]) => {
        if (projectId === null || Boolean(records.length)) {
          return of();
        }
        return this._taskApiService
          .getTaskList$(
            projectId,
            createListPayload<TTaskSortField, TTaskQueryField, TTaskListRequest>(
              pagination,
              sort,
              query.data,
            ),
          )
          .pipe(
            map((response) => taskActions.loadListSuccess({ result: response })),
            catchError((error) => {
              return of(taskActions.loadListFailure({ error }));
            }),
          );
      }),
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.openedProjectId),
      filter((action) => !action.projectId),
      map(() => taskActions.resetState()),
    ),
  );

  // TODO: Replace 'any' with proper types
  private _fetchList$(
    actions: ActionCreator[],
    successCallback: (response: { result: TTaskListResponse }) => void,
    errorCallback: (error: any) => any,
  ): Observable<any> {
    return this._actions$.pipe(
      ofType(...actions),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(taskFeature.selectPagination),
        this._store.select(taskFeature.selectSort),
        this._store.select(taskFeature.selectQuery),
      ),
      distinctUntilObjectChanged(this._store.select(globalFeature.selectOpenedProjectId)),
      switchMap(([, projectId, pagination, sort, query]) => {
        if (projectId === null) {
          // TODO: Implement error handling
          return of();
        }
        return this._taskApiService
          .getTaskList$(
            projectId,
            createListPayload<TTaskSortField, TTaskQueryField, TTaskListRequest>(
              pagination,
              sort,
              query.data,
            ),
          )
          .pipe(
            map((response) => successCallback({ result: response })),
            catchError((error) => {
              return of(errorCallback({ error }));
            }),
          );
      }),
    );
  }
}
