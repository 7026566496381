import { createActionGroup, props } from '@ngrx/store';

import { getEntityListActions } from '@ocp/utils-advanced/ngrx';

import { DOCUMENT_JOB_STORE_OPTIONS } from './document-job-store-options.constant';
import type {
  TDocumentJob,
  TDocumentJobQueryField,
  TDocumentJobSortField,
  TDocumentJobStartRequest,
} from '../types';

export const documentJobActions = createActionGroup({
  source: 'DocumentJob',
  events: {
    ...getEntityListActions<TDocumentJob, TDocumentJobQueryField, TDocumentJobSortField>()(
      DOCUMENT_JOB_STORE_OPTIONS,
    ),
    'Start Jobs': props<{ payload: TDocumentJobStartRequest }>(),
  },
});
