import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TaskManagerComponent } from '../containers';

@Injectable({ providedIn: 'root' })
export class TaskManagerService {
  constructor(private _dialog: MatDialog) {}

  public openTaskManagerDialog(): void {
    const dialogRef = this._dialog.open(TaskManagerComponent, {
      maxHeight: '90vh',
      maxWidth: '90vw',
      width: '100%',
      data: {
        isDialogMode: true,
      },
    });

    dialogRef.afterClosed().subscribe((response: unknown) => {
      console.log('response of dialog: ', response);
    });
  }
}
