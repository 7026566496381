import { Injectable } from '@angular/core';

import { tap } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { TaskManagerService } from '../service';
import { taskManagerActions } from './task-manager.actions';

@Injectable({ providedIn: 'root' })
export class TaskManagerEffects {
  constructor(
    private _taskManagerService: TaskManagerService,
    private _actions$: Actions,
  ) {}

  openTaskManagerDialog$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(taskManagerActions.openDialog),
        tap(() => this._taskManagerService.openTaskManagerDialog()),
      );
    },
    { dispatch: false },
  );
}
