import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { type Observable } from 'rxjs';

import { OcpUrlBuilder } from '@ocp/utils/models';

import { EnvironmentService } from '@libs/core/services';
import { createHttpContext } from '@libs/core/utils';
import { SCHEMAS } from '@libs/openapi/main';

import type {
  TDocumentJobListRequest,
  TDocumentJobListResponse,
  TDocumentJobStartRequest,
  TDocumentJobStartResponse,
} from '../types';

@Injectable({ providedIn: 'root' })
export class DocumentJobApiService {
  private _urlBuilder: OcpUrlBuilder;

  constructor(
    private readonly _http: HttpClient,
    private readonly _environmentService: EnvironmentService,
  ) {
    this._urlBuilder = new OcpUrlBuilder(
      `${this._environmentService.getProp('API_PREFIX')}/projects`,
    );
  }

  public getList$(
    projectId: number,
    payload?: TDocumentJobListRequest,
  ): Observable<TDocumentJobListResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'document-jobs', 'list']);

    return this._http.post<TDocumentJobListResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['DocumentJobListRequest'],
        RESPONSE_SCHEMA: SCHEMAS['DocumentJobListResponse'],
      }),
    });
  }

  public startJobs$(
    projectId: number,
    payload: TDocumentJobStartRequest,
  ): Observable<TDocumentJobStartResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'jobs', 'run']);

    return this._http.post<TDocumentJobStartResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['JobDataDto'],
        RESPONSE_SCHEMA: SCHEMAS['JobStatusDto'],
      }),
    });
  }
}
