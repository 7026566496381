import type { OcpSearchConfig } from '@ocp/ui-kit/search';

import type { TDocumentJobQueryField } from '../../../types';

export const DEFAULT_DOCUMENT_JOB_TABLE_SEARCH_CONFIG: Omit<
  OcpSearchConfig<TDocumentJobQueryField>,
  'query' | 'queryControl'
> = {
  settingsIcon: { icon: 'search-filter', isCustom: true },
  translateSection: 'SEARCH',
  placeholder: 'PLACEHOLDER',
  selectableQueryFields: [
    {
      labelKey: 'FILTER_BY_TASK_PROGRESS',
      value: 'taskProgress',
    },
    {
      labelKey: 'FILTER_BY_TASK_NAME',
      value: 'taskName',
    },
    {
      labelKey: 'FILTER_BY_EXTERNAL_VERSION',
      value: 'externalVersion',
    },
    {
      labelKey: 'FILTER_BY_DATAMODEL_VERSION_ID',
      value: 'datamodelVersionId',
    },
    {
      labelKey: 'FILTER_BY_DATAMODEL_NAME',
      value: 'datamodelName',
    },
    {
      labelKey: 'FILTER_BY_NAME',
      value: 'name',
    },
  ],
};
