import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { type Observable } from 'rxjs';

import { OcpUrlBuilder } from '@ocp/utils/models';

import { EnvironmentService } from '@libs/core/services';
import { createHttpContext } from '@libs/core/utils';
import { SCHEMAS } from '@libs/openapi/main';

import type {
  TTaskJobActionCommonResponse,
  TTaskJobCancelRequest,
  TTaskJobListRequest,
  TTaskJobListResponse,
  TTaskJobRetryRequest,
  TTaskJobStartRequest,
} from '../types';

@Injectable({ providedIn: 'root' })
export class TaskJobApiService {
  private _urlBuilder: OcpUrlBuilder;

  constructor(
    private readonly _http: HttpClient,
    private readonly _environmentService: EnvironmentService,
  ) {
    this._urlBuilder = new OcpUrlBuilder(
      `${this._environmentService.getProp('API_PREFIX')}/projects`,
    );
  }

  public getList$(
    projectId: number,
    payload?: TTaskJobListRequest,
  ): Observable<TTaskJobListResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'jobs', 'list']);
    return this._http.post<TTaskJobListResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['JobListRequest'],
        RESPONSE_SCHEMA: SCHEMAS['SwaggerCustomPageJobDto'],
      }),
    });
  }

  public startJobs$(
    projectId: number,
    payload: TTaskJobStartRequest,
  ): Observable<TTaskJobActionCommonResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'jobs', 'run']);

    return this._http.post<TTaskJobActionCommonResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['JobDataDto'],
        RESPONSE_SCHEMA: SCHEMAS['JobStatusDto'],
      }),
    });
  }

  public retryJobs$(
    projectId: number,
    payload: TTaskJobRetryRequest,
  ): Observable<TTaskJobActionCommonResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'jobs', 'retry']);

    return this._http.post<TTaskJobActionCommonResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['TaskListDto'],
        RESPONSE_SCHEMA: SCHEMAS['JobStatusDto'],
      }),
    });
  }

  public cancelJobs$(
    projectId: number,
    payload: TTaskJobCancelRequest,
  ): Observable<TTaskJobActionCommonResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'jobs', 'cancel']);

    return this._http.post<TTaskJobActionCommonResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['TaskListDto'],
        RESPONSE_SCHEMA: SCHEMAS['JobStatusDto'],
      }),
    });
  }
}
