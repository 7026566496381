import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { OcpTranslationSectionDirective } from '@ocp/fusion-cdk/translate';
import { OcpButtonUniversalComponent } from '@ocp/ui-kit/button';
import { OcpSelectComponent } from '@ocp/ui-kit/form';

import { TaskManagerActions } from './task-manager-actions.model';

@Component({
  selector: 'lib-task-manager-actions',
  standalone: true,
  templateUrl: './task-manager-actions.component.html',
  styleUrl: './task-manager-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [OcpTranslationSectionDirective, OcpButtonUniversalComponent, OcpSelectComponent],
})
export class TaskManagerActionsComponent<TEntity> {
  configSig = input.required<TaskManagerActions<TEntity>>({ alias: 'config' });
}
