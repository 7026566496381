import { createActionGroup } from '@ngrx/store';

import { getEntityListActions } from '@ocp/utils-advanced/ngrx';

import type { TTask } from '../types';
import { TASK_STORE_OPTIONS } from './task-store-options.const';

export const taskActions = createActionGroup({
  source: 'Task',
  events: {
    ...getEntityListActions<TTask>()(TASK_STORE_OPTIONS),
  },
});
