import { createActionGroup, props } from '@ngrx/store';

import { getEntityListActions } from '@ocp/utils-advanced/ngrx';

import { TASK_JOB_STORE_OPTIONS } from './task-job-store-options.constant';
import type {
  TTaskJob,
  TTaskJobCancelRequest,
  TTaskJobQueryField,
  TTaskJobRetryRequest,
  TTaskJobSortField,
  TTaskJobStartRequest,
} from '../types';

export const taskJobActions = createActionGroup({
  source: 'TaskJob',
  events: {
    ...getEntityListActions<TTaskJob, TTaskJobQueryField, TTaskJobSortField>()(
      TASK_JOB_STORE_OPTIONS,
    ),
    'Start Jobs': props<{ payload: TTaskJobStartRequest }>(),
    'Retry Jobs': props<{ payload: TTaskJobRetryRequest }>(),
    'Cancel Jobs': props<{ payload: TTaskJobCancelRequest }>(),
  },
});
