import { OcpButtonRealisation } from '@ocp/ui-kit/button';
import { OcpSelectConfig } from '@ocp/ui-kit/form';
import { OcpRequiredAndPartial } from '@ocp/utils/types';

export type TTaskManagerActionsProps<TEntity> = OcpRequiredAndPartial<
  TaskManagerActions<TEntity>,
  'controls' | 'startActionButton',
  never
>;

export class TaskManagerActions<TEntity> {
  public controls!: {
    [K in keyof TEntity]: OcpSelectConfig<TEntity[K]>;
  };
  public startActionButton!: OcpButtonRealisation;

  constructor(props: TTaskManagerActionsProps<TEntity>) {
    Object.assign(this, props);
  }

  public getControls(): OcpSelectConfig<TEntity[keyof TEntity]>[] {
    return Object.values(this.controls) as OcpSelectConfig<TEntity[keyof TEntity]>[];
  }
}
