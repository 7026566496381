import { createFeature, createReducer, on } from '@ngrx/store';

import { OcpQueryData } from '@ocp/utils/types';
import { OcpStoreEntityList } from '@ocp/utils-advanced/types';
import { getDefaultStoreEntityList } from '@ocp/utils-advanced/constants';
import { getEntityListHandlers } from '@ocp/utils-advanced/ngrx';

import { TASK_STORE_OPTIONS } from './task-store-options.const';
import type { TTask, TTaskQueryField, TTaskSortField } from '../types';
import { taskActions } from './task.actions';

const defaultQueryData: OcpQueryData<TTaskQueryField>[] = [
  {
    type: 'CONTAINS',
    field: 'name',
    q: '',
  },
];

type TInitialState = OcpStoreEntityList<
  TTask,
  typeof TASK_STORE_OPTIONS,
  TTaskQueryField,
  TTaskSortField
>;
const defaultState = getDefaultStoreEntityList<TTask, TTaskQueryField, TTaskSortField>()(
  TASK_STORE_OPTIONS,
);

const initialState: TInitialState = {
  ...defaultState,
  query: {
    ...defaultState.query,
    data: defaultQueryData,
  },
  sort: { by: 'name', order: 'ASC' },
};

const defaultHandlers = getEntityListHandlers<TTask, object, TTaskQueryField, TTaskSortField>()(
  TASK_STORE_OPTIONS,
);

const taskReducer = createReducer(
  initialState,
  on(taskActions.loadList, (state) => defaultHandlers.loadList(state)),
  on(taskActions.loadListSuccess, (state, { result: response }) =>
    defaultHandlers.loadListSuccess(state, response),
  ),
  on(taskActions.loadListFailure, (state, { error }) =>
    defaultHandlers.loadListFailure(state, error),
  ),
);

export const taskFeature = createFeature({
  name: 'task',
  reducer: taskReducer,
});
