import { createFeature, createReducer, on } from '@ngrx/store';

import { getDefaultStoreEntityList } from '@ocp/utils-advanced/constants';
import { getEntityListHandlers } from '@ocp/utils-advanced/ngrx';
import type { OcpStoreEntityList } from '@ocp/utils-advanced/types';
import type { OcpQueryData } from '@ocp/utils/types';

import { TASK_JOB_STORE_OPTIONS } from './task-job-store-options.constant';
import { TTaskJob, type TTaskJobQueryField, type TTaskJobSortField } from '../types';
import { taskJobActions } from './task-job.actions';

type TInitialState = OcpStoreEntityList<
  TTaskJob,
  typeof TASK_JOB_STORE_OPTIONS,
  TTaskJobQueryField,
  TTaskJobSortField
>;

const defaultQueryData: OcpQueryData<TTaskJobQueryField>[] = [
  {
    type: 'CONTAINS',
    field: 'name',
    q: '',
  },
];

const defaultState = getDefaultStoreEntityList<TTaskJob, TTaskJobQueryField, TTaskJobSortField>()(
  TASK_JOB_STORE_OPTIONS,
);

const initialState: TInitialState = {
  ...defaultState,
  query: {
    ...defaultState.query,
    data: defaultQueryData,
  },
  sort: { by: 'name', order: 'ASC' },
};

const defaultHandlers = getEntityListHandlers<
  TTaskJob,
  object,
  TTaskJobQueryField,
  TTaskJobSortField
>()(TASK_JOB_STORE_OPTIONS);

const taskJobReducer = createReducer(
  initialState,
  on(taskJobActions.loadList, (state) => defaultHandlers.loadList(state)),
  on(taskJobActions.loadListWithForce, (state) => defaultHandlers.loadList(state, true)),
  on(taskJobActions.loadListSuccess, (state, { result: response }) =>
    defaultHandlers.loadListSuccess(state, response),
  ),
  on(taskJobActions.loadListFailure, (state, { error }) =>
    defaultHandlers.loadListFailure(state, error),
  ),
  on(taskJobActions.refreshListSuccess, (state, { result: response }) =>
    defaultHandlers.refreshListSuccess(state, response),
  ),
  on(taskJobActions.refreshListFailure, (state, { error }) =>
    defaultHandlers.refreshListFailure(state, error),
  ),
  on(taskJobActions.setPageSize, (state, { pageSize, refreshBehavior }) =>
    defaultHandlers.setPageSize(state, pageSize, refreshBehavior),
  ),
  on(taskJobActions.setPageIndex, (state, { pageIndex, refreshBehavior }) =>
    defaultHandlers.setPageIndex(state, pageIndex, refreshBehavior),
  ),
  on(taskJobActions.setSort, (state, { sort, refreshBehavior }) =>
    defaultHandlers.setSort(state, sort, refreshBehavior),
  ),
  on(taskJobActions.setQueryData, (state, { queryData }) =>
    defaultHandlers.setQueryData(state, queryData, defaultQueryData),
  ),
  on(taskJobActions.resetQueryFilter, (state) =>
    defaultHandlers.resetQueryFilter(state, defaultQueryData),
  ),
  on(taskJobActions.setQueryFilterDisabled, (state, { isDisabled }) =>
    defaultHandlers.setQueryFilterDisabled(state, isDisabled, defaultQueryData),
  ),
);

export const taskJobFeature = createFeature({
  name: 'taskJob',
  reducer: taskJobReducer,
});
