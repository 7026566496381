@if (data?.isDialogMode) {
  <div ocpTranslateSection="TASK_MANAGEMENT" class="task-manager-dialog-header">
    <h5 mat-dialog-title>{{ dialogHeader | ocpTranslate }}</h5>
    <mat-dialog-actions>
      <ocp-button-universal mat-dialog-close [config]="closeDialogButtonConfig" />
    </mat-dialog-actions>
  </div>
}
<mat-dialog-content>
  <div class="task-manager-header">
    <mat-tab-group>
      <mat-tab label="{{ documentJobsTitle | ocpTranslate }}">
        <ng-template matTabContent>
          <lib-task-manager-actions [config]="documentJobActionsConfig" />
          <lib-document-job />
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ taskJobsTitle | ocpTranslate }}">
        <ng-template matTabContent>
          <lib-task-manager-actions [config]="taskJobActionsConfig" />
          <lib-task-job />
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-dialog-content>
