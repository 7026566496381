import type { OcpSearchConfig } from '@ocp/ui-kit/search';

import type { TTaskJobQueryField } from '../../../types';

export const DEFAULT_TASK_JOB_TABLE_SEARCH_CONFIG: Omit<
  OcpSearchConfig<TTaskJobQueryField>,
  'query' | 'queryControl'
> = {
  settingsIcon: { icon: 'search-filter', isCustom: true },
  translateSection: 'SEARCH',
  placeholder: 'PLACEHOLDER',
  selectableQueryFields: [
    {
      labelKey: 'FILTER_BY_TRIGGERED_BY',
      value: 'triggeredBy',
    },
    {
      labelKey: 'FILTER_BY_TRIGGERED_AT',
      value: 'triggeredAt',
    },
    {
      labelKey: 'FILTER_BY_PROGRESS',
      value: 'progress',
    },
    {
      labelKey: 'FILTER_BY_STATUS',
      value: 'status',
    },
    {
      labelKey: 'FILTER_BY_EXTERNAL_VERSION',
      value: 'externalVersion',
    },
    {
      labelKey: 'FILTER_BY_DATAMODEL_VERSION_NAME',
      value: 'datamodelVersionName',
    },
    {
      labelKey: 'FILTER_BY_DATAMODEL_NAME',
      value: 'datamodelName',
    },
    {
      labelKey: 'FILTER_BY_NAME',
      value: 'name',
    },
    {
      labelKey: 'FILTER_BY_FILE_NAME',
      value: 'fileName',
    },
  ],
};
