import { Injectable } from '@angular/core';

import {
  catchError,
  debounceTime,
  filter,
  map,
  type Observable,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { type ActionCreator, Store } from '@ngrx/store';

import { distinctUntilObjectChanged } from '@ocp/utils/rxjs';

import { API_REQUEST_DEBOUNCE_TIME } from '@libs/core/constants';
import { createListPayload } from '@libs/core/utils';
import { globalActions, globalFeature } from '@libs/core/store';

import { DocumentJobApiService } from '../services';
import type {
  TDocumentJobListRequest,
  TDocumentJobListResponse,
  TDocumentJobQueryField,
  TDocumentJobSortField,
} from '../types';
import { documentJobActions } from './document-job.actions';
import { documentJobFeature } from './document-job.reducer';

@Injectable({ providedIn: 'root' })
export class DocumentJobEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _documentJobApiService: DocumentJobApiService,
  ) {}

  loadDocumentJobs$ = createEffect(() =>
    this._fetchDocumentJobs$(
      [
        documentJobActions.setQueryData,
        documentJobActions.resetQueryFilter,
        documentJobActions.setQueryFilterDisabled,
      ],
      documentJobActions.loadListSuccess,
      documentJobActions.loadListFailure,
    ),
  );

  refreshDocumentJobs$ = createEffect(() =>
    this._fetchDocumentJobs$(
      [documentJobActions.setPageSize, documentJobActions.setPageIndex, documentJobActions.setSort],
      documentJobActions.refreshListSuccess,
      documentJobActions.refreshListFailure,
    ),
  );

  startDocumentJobs$ = createEffect(() =>
    this._actions$.pipe(
      ofType(documentJobActions.startJobs),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(this._store.select(globalFeature.selectOpenedProjectId)),
      distinctUntilObjectChanged(this._store.select(globalFeature.selectOpenedProjectId)),
      switchMap(([payload, projectId]) => {
        if (projectId === null) {
          // TODO: Implement error handling
          return of();
        }
        return this._documentJobApiService.startJobs$(projectId, { ...payload.payload }).pipe(
          map(() => documentJobActions.loadList()),
          catchError(() => {
            return of();
          }),
        );
      }),
    ),
  );

  loadList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(documentJobActions.loadList),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(documentJobFeature.selectPagination),
        this._store.select(documentJobFeature.selectSort),
        this._store.select(documentJobFeature.selectQuery),
        this._store.select(documentJobFeature.selectRecords),
      ),
      switchMap(([, projectId, pagination, sort, query, records]) => {
        if (projectId === null || Boolean(records.length)) {
          return of();
        }
        return this._documentJobApiService
          .getList$(
            projectId,
            createListPayload<
              TDocumentJobSortField,
              TDocumentJobQueryField,
              TDocumentJobListRequest
            >(pagination, sort, query.data),
          )
          .pipe(
            map((response) => documentJobActions.loadListSuccess({ result: response })),
            catchError((error) => {
              return of(documentJobActions.loadListFailure({ error }));
            }),
          );
      }),
    ),
  );

  loadListWithForce$ = createEffect(() =>
    this._actions$.pipe(
      ofType(documentJobActions.loadListWithForce),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(documentJobFeature.selectPagination),
        this._store.select(documentJobFeature.selectSort),
        this._store.select(documentJobFeature.selectQuery),
      ),
      switchMap(([, projectId, pagination, sort, query]) => {
        if (projectId === null) {
          return of();
        }
        return this._documentJobApiService
          .getList$(
            projectId,
            createListPayload<
              TDocumentJobSortField,
              TDocumentJobQueryField,
              TDocumentJobListRequest
            >(pagination, sort, query.data),
          )
          .pipe(
            map((response) => documentJobActions.loadListSuccess({ result: response })),
            catchError((error) => {
              return of(documentJobActions.loadListFailure({ error }));
            }),
          );
      }),
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.openedProjectId),
      filter((action) => !action.projectId),
      map(() => documentJobActions.resetState()),
    ),
  );

  // TODO: Replace 'any' with proper types
  private _fetchDocumentJobs$(
    actions: ActionCreator[],
    successCallback: (response: { result: TDocumentJobListResponse }) => void,
    errorCallback: (error: any) => any,
  ): Observable<any> {
    return this._actions$.pipe(
      ofType(...actions),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(documentJobFeature.selectPagination),
        this._store.select(documentJobFeature.selectSort),
        this._store.select(documentJobFeature.selectQuery),
      ),
      distinctUntilObjectChanged(this._store.select(globalFeature.selectOpenedProjectId)),
      switchMap(([, projectId, pagination, sort, query]) => {
        if (projectId === null) {
          // TODO: Implement error handling
          return of();
        }
        return this._documentJobApiService
          .getList$(
            projectId,
            createListPayload<
              TDocumentJobSortField,
              TDocumentJobQueryField,
              TDocumentJobListRequest
            >(pagination, sort, query.data),
          )
          .pipe(
            map((response) => successCallback({ result: response })),
            catchError((error) => {
              return of(errorCallback({ error }));
            }),
          );
      }),
    );
  }
}
