@let selectControls = configSig().getControls();
@let startAction = configSig().startActionButton;

<div ocpTranslateSection="TASK_MANAGEMENT" class="task-manager-actions-area">
  <div class="task-manager-actions-select-info">
    @if (selectControls && selectControls.length) {
      @for (select of selectControls; track $index) {
        <ocp-select [config]="select" />
      }
    }
  </div>
  <div class="task-manager-actions-buttons">
    @if (startAction) {
      <ocp-button-universal [config]="startAction" />
    }
  </div>
</div>
