import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { type Observable } from 'rxjs';

import { OcpUrlBuilder } from '@ocp/utils/models';

import { EnvironmentService } from '@libs/core/services';
import { createHttpContext } from '@libs/core/utils';
import { SCHEMAS } from '@libs/openapi/main';

import type { TTaskListRequest, TTaskListResponse } from '../types';

@Injectable({ providedIn: 'root' })
export class TaskApiService {
  private _urlBuilder: OcpUrlBuilder;

  constructor(
    private readonly _http: HttpClient,
    private readonly _environmentService: EnvironmentService,
  ) {
    this._urlBuilder = new OcpUrlBuilder(
      `${this._environmentService.getProp('API_PREFIX')}/projects`,
    );
  }

  public getTaskList$(
    projectId: number,
    payload?: TTaskListRequest,
  ): Observable<TTaskListResponse> {
    const url = this._urlBuilder.buildUrl([projectId, 'tasks', 'list']);

    return this._http.post<TTaskListResponse>(url, payload, {
      context: createHttpContext({
        REQUEST_SCHEMA: SCHEMAS['TaskListRequest'],
        RESPONSE_SCHEMA: SCHEMAS['SwaggerCustomPageTaskDto'],
      }),
    });
  }
}
