import { Injectable } from '@angular/core';

import { concatMap, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { documentActions } from '@libs/modules/document';
import { tagActions } from '@libs/modules/tag';

import { filterManagerActions } from './filter-manager.actions';

@Injectable({
  providedIn: 'root',
})
export class FilterManagerEffects {
  constructor(private _actions$: Actions) {}

  disableTagFilter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(documentActions.setFilterItem),
      map(({ item }) =>
        tagActions.setFilterItemDisabled({
          isDisabled: Boolean(item),
        }),
      ),
    ),
  );

  disableDocumentFilter$ = createEffect(() =>
    this._actions$.pipe(
      ofType(tagActions.setFilterItem),
      map(({ item }) =>
        documentActions.setFilterItemDisabled({
          isDisabled: Boolean(item),
        }),
      ),
    ),
  );

  resetFilters$ = createEffect(() =>
    this._actions$.pipe(
      ofType(filterManagerActions.resetFilters),
      concatMap(() =>
        of(
          tagActions.setFilterItem({ item: null }),
          tagActions.setFilterItemDisabled({ isDisabled: false }),
          documentActions.setFilterItem({ item: null }),
          documentActions.setFilterItemDisabled({ isDisabled: false }),
        ),
      ),
    ),
  );
}
